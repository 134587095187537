import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User, Township } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from '../../../settings/dialog/confirmation-dialog/confirmation-dialog.component';
import { getAuth } from 'firebase/auth';
import { db } from 'src/app/app.component';
export interface DialogData {
  mustSelect: boolean;
}
@Component({
  selector: 'app-change-township-dialog',
  templateUrl: './change-township-dialog.component.html',
  styleUrls: ['./change-township-dialog.component.scss'],
})
export class ChangeTownshipDialogComponent implements OnInit {
  searchForm: UntypedFormGroup;
  filteredTownships: Township[] = [];
  allTownships: Township[] = [];
  environments: string[] = [];
  selectedIndex: number;
  loaded: boolean = false;
  isAdmin: boolean = false;
  userData: User;
  currentPrefix: string = environment.prefix;
  allowedTownshipIds: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<ChangeTownshipDialogComponent>,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.searchForm = this.fb.group({
      search: [''],
    });

    (
      await getDocs(query(collection(db, `/township/`), orderBy('name', 'asc')))
    ).forEach((doc) => {
      const saveObj = { ...doc.data(), id: doc.id } as Township;
      this.allTownships.push(saveObj);
    });

    const firebaseUser = getAuth().currentUser;
    if (firebaseUser) {
      this.userData = {
        ...((
          await getDoc(doc(db, `users/${firebaseUser.uid}`))
        ).data() as User),
        id: firebaseUser.uid,
      };
    }

    if (this.userData.rights === 'admin') {
      this.isAdmin = true;
      this.environments = [
        'Lokale Bon',
        'Groene Bon',
        'Duurzaamwonen Bon',
        'Toegangsbon',
        'Verborgen',
      ];
    }

    if (
      (!this.userData.rights || this.userData.rights !== 'admin') &&
      this.userData.orgRights
    ) {
      this.userData.orgRights.forEach((orgItem) => {
        this.allowedTownshipIds.push(orgItem.townshipId);
        const voucherPrefix = this.allTownships.find(
          ({ id }) => id === orgItem.townshipId
        ).voucherPrefix;
        switch (voucherPrefix) {
          case 'lokale':
            if (!this.environments.includes('Lokale Bon')) {
              this.environments.push('Lokale Bon');
            }
            break;
          case 'groene':
            if (!this.environments.includes('Groene Bon')) {
              this.environments.push('Groene Bon');
            }
            break;
          case 'duurzaamwonen':
            if (!this.environments.includes('Duurzaamwonen Bon')) {
              this.environments.push('Duurzaamwonen Bon');
            }
            break;
          case 'toegangs':
            if (!this.environments.includes('Toegangsbon')) {
              this.environments.push('Toegangsbon');
            }
            break;
        }
      });
    }

    switch (environment.prefix) {
      case 'lokale':
        this.selectedIndex = 0;
        break;
      case 'groene':
        this.selectedIndex = 1;
        break;
      case 'duurzaamwonen':
        this.selectedIndex = 2;
        break;
      case 'toegangs':
        this.selectedIndex = 3;
        break;
      case 'hidden':
        this.selectedIndex = 4;
        break;
    }

    this.searchForm.controls.search.valueChanges.subscribe(() => {
      this.filterItems();
    });
  }

  tabChanged = (tabChangeEvent): void => {
    this.loaded = false;
    switch (tabChangeEvent.tab.textLabel) {
      case 'Lokale Bon':
        this.currentPrefix = 'lokale';
        break;
      case 'Groene Bon':
        this.currentPrefix = 'groene';
        break;
      case 'Duurzaamwonen Bon':
        this.currentPrefix = 'duurzaamwonen';
        break;
      case 'Toegangsbon':
        this.currentPrefix = 'toegangs';
        break;
      case 'Verborgen':
        this.currentPrefix = 'hidden';
        break;
    }
    this.filterItems();
    this.loaded = true;
  };

  filterItems() {
    const searchQuery = this.searchForm.controls.search.value;
    this.filteredTownships = [];
    this.allTownships.forEach((township) => {
      let passesSearchFilter = false;
      if (!township.hideSource) {
        if (
          township.voucherPrefix &&
          township.voucherPrefix === this.currentPrefix
        ) {
          passesSearchFilter = true;
        }
      } else {
        if (this.currentPrefix === 'hidden') {
          passesSearchFilter = true;
        }
      }

      if (!this.isAdmin && passesSearchFilter) {
        passesSearchFilter = false;
        if (this.allowedTownshipIds.includes(township.id)) {
          passesSearchFilter = true;
        }
      }

      if (searchQuery && passesSearchFilter) {
        passesSearchFilter = false;
        if (
          township.name
            .toLowerCase()
            .includes(searchQuery.toString().toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }

      if (passesSearchFilter) {
        this.filteredTownships.push(township);
      }
    });
  }

  async hideTownship(
    townshipId: string,
    $event,
    hide?: boolean
  ): Promise<void> {
    $event.stopPropagation();
    const docRef = doc(db, `township/${townshipId}`);
    await setDoc(docRef, { hideSource: hide ? true : false }, { merge: true });
    this.snackBar.open(
      hide ? 'Omgeving verstopt' : 'Omgeving beschikbaar',
      'X',
      {
        duration: 7500,
      }
    );
  }

  async deleteTownship(townshipId: string, $event): Promise<void> {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '375px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await deleteDoc(doc(db, `township/${townshipId}`));
        this.snackBar.open('Beheeromgeving verwijderd', 'X', {
          duration: 7500,
        });
      }
    });
  }

  close(selectedId: string) {
    this.dialogRef.close(selectedId);
  }
}
