// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-card {
  padding: 16px 24px;
  max-width: 825px;
  margin: 16px auto;
}

.page .page-title {
  padding: 16px 16px 10px 16px;
  margin: auto;
}
.page .page-title .page-icon mat-icon {
  color: #1a697f;
}
.page .container {
  display: flex;
  border-bottom: black 1px solid;
  border-left: #1a697f 2px solid;
  padding: 10px 5px 5px 10px;
}
.page .container .column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page .container .column .multi-factor-status {
  display: flex;
  align-items: center;
}
.page .container .column .multi-factor-status mat-icon {
  margin-right: 10px;
  border-radius: 50%;
  color: white;
}
.page .container .column .multi-factor-status .check-icon {
  background-color: var(--master);
}
.page .container .column .multi-factor-status .close-icon {
  background-color: var(--warn);
}
.page .container .column:last-child {
  align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/account/account.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAGE;EACE,4BAAA;EACA,YAAA;AAAJ;AAGM;EACE,cAAA;AADR;AAKE;EACE,aAAA;EACA,8BAAA;EACA,8BAAA;EACA,0BAAA;AAHJ;AAII;EACE,OAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAFN;AAGM;EACE,aAAA;EACA,mBAAA;AADR;AAEQ;EACE,kBAAA;EACA,kBAAA;EACA,YAAA;AAAV;AAEQ;EACE,+BAAA;AAAV;AAEQ;EACE,6BAAA;AAAV;AAII;EACE,qBAAA;AAFN","sourcesContent":["mat-card {\n  padding: 16px 24px;\n  max-width: 825px;\n  margin: 16px auto;\n}\n\n.page {\n  .page-title {\n    padding: 16px 16px 10px 16px;\n    margin: auto;\n\n    .page-icon {\n      mat-icon {\n        color: #1a697f;\n      }\n    }\n  }\n  .container {\n    display: flex;\n    border-bottom: black 1px solid;\n    border-left: #1a697f 2px solid;\n    padding: 10px 5px 5px 10px;\n    .column {\n      flex: 1;\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      .multi-factor-status {\n        display: flex;\n        align-items: center;\n        mat-icon {\n          margin-right: 10px;\n          border-radius: 50%;\n          color: white;\n        }\n        .check-icon {\n          background-color: var(--master);\n        }\n        .close-icon {\n          background-color: var(--warn);\n        }\n      }\n    }\n    .column:last-child {\n      align-items: flex-end;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
