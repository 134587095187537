// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  margin: 12px;
}

.widgets-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (max-width: 649px) and (min-width: 0px) {
  .widget-wrapper {
    width: 100% !important;
  }
}
.loading-wrapper .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/app/statistics/statistics.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;AAEF;;AACA;EACE;IACE,sBAAA;EAEF;AACF;AACE;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;AACJ","sourcesContent":[".page {\n  flex-flow: row wrap;\n  box-sizing: border-box;\n  display: flex;\n  margin: 12px;\n}\n.widgets-wrapper {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 100%;\n}\n\n@media only screen and (max-width: 649px) and (min-width: 0px) {\n  .widget-wrapper {\n    width: 100% !important;\n  }\n}\n.loading-wrapper {\n  .loading {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
