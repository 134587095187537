// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-card {
  padding: 16px 24px;
  max-width: 825px;
  margin: 16px auto;
}

.page .page-title {
  padding: 16px 16px 10px 16px;
  margin: auto;
  justify-content: flex-start;
}
.page .page-title mat-icon {
  margin-right: 10px;
  cursor: pointer;
}
.page .container {
  padding: 16px 16px 10px 16px;
}
.page .container .form-item {
  margin: 10px 0 10px 0;
  display: flex;
}
.page .container .verify-button {
  display: flex;
  justify-content: flex-end;
}
.page .container .full-width {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/account/multi-factor/multi-factor.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAGE;EACE,4BAAA;EACA,YAAA;EACA,2BAAA;AAAJ;AACI;EACE,kBAAA;EACA,eAAA;AACN;AAEE;EACE,4BAAA;AAAJ;AACI;EACE,qBAAA;EACA,aAAA;AACN;AACI;EACE,aAAA;EACA,yBAAA;AACN;AACI;EACE,WAAA;AACN","sourcesContent":["mat-card {\n  padding: 16px 24px;\n  max-width: 825px;\n  margin: 16px auto;\n}\n\n.page {\n  .page-title {\n    padding: 16px 16px 10px 16px;\n    margin: auto;\n    justify-content: flex-start;\n    mat-icon {\n      margin-right: 10px;\n      cursor: pointer;\n    }\n  }\n  .container {\n    padding: 16px 16px 10px 16px;\n    .form-item {\n      margin: 10px 0 10px 0;\n      display: flex;\n    }\n    .verify-button {\n      display: flex;\n      justify-content: flex-end;\n    }\n    .full-width {\n      width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
