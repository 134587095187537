import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { OrgUser, TownshipUser, User } from '../interfaces';
import { DeleteUserComponent } from './dialogs/delete-user/delete-user.component';
import { ManageUserComponent } from './dialogs/manage-user/manage-user.component';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  env = environment;
  townshipId = localStorage.getItem('township') as string;
  users: Observable<TownshipUser[]>;
  displayedColumns = ['email', 'rights', 'buttons'];
  organisationId: string;
  userId: string;
  orgUser?: OrgUser;

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    this.userId = getAuth().currentUser.uid;
    if (this.env.env === 'township') {
      this.users = this.db
        .collection<TownshipUser>(`township/${this.townshipId}/users`, (ref) =>
          ref.orderBy('email')
        )
        .valueChanges({ idField: 'id' });
    } else {
      const userDoc = doc(this.db.firestore, `users/${this.userId}`);
      const userData = (await getDoc(userDoc)).data() as User;
      if (userData.organisation) {
        const orgUserDoc = doc(
          this.db.firestore,
          `township/${userData.township}/organisations/${userData.organisation}/users/${this.userId}`
        );
        this.orgUser = (await getDoc(orgUserDoc)).data() as OrgUser;
        if (
          !this.orgUser ||
          (this.orgUser.rights !== 'admin' && this.orgUser.rights !== 'owner')
        ) {
          return;
        }
        this.organisationId = userData.organisation;
        this.users = this.db
          .collection<TownshipUser>(
            `township/${this.townshipId}/organisations/${this.organisationId}/users`,
            (ref) => ref.orderBy('email')
          )
          .valueChanges({ idField: 'id' });
        this.displayedColumns.unshift('name');
      }
    }
    this.users.subscribe((users) => {});
  }

  editUser(user?: TownshipUser) {
    this.dialog.open(ManageUserComponent, {
      width: '475px',
      data: { user, orgId: this.organisationId, ownUserId: this.userId },
    });
  }

  deleteUser(user: TownshipUser) {
    if (user && user.rights == 'owner') {
      return this.snackBar.open('Je kan de eigenaar niet verwijderen.', 'X', {
        duration: 3000,
      });
    }
    if (user && user.id === this.userId) {
      return this.snackBar.open('Je kan jezelf niet verwijderen.', 'X', {
        duration: 3000,
      });
    }
    this.dialog.open(DeleteUserComponent, {
      width: '475px',
      data: { user, orgId: this.organisationId },
    });
  }
}
