import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { lastValueFrom } from 'rxjs';
import { TownshipUser } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';

export interface DialogData {
  user: TownshipUser;
  orgId?: string;
}

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss'],
})
export class DeleteUserComponent implements OnInit {
  townshipId = localStorage.getItem('township') as string;
  saving: boolean;

  constructor(
    public dialogRef: MatDialogRef<DeleteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private http: HttpClient,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  async delete() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    this.dialogRef.disableClose = true;
    const requestUrl = `${environment.functionsUrl}/accountManageTownshipUser`;
    const result = (
      (await lastValueFrom(
        this.http.post(requestUrl, {
          data: {
            type: 'delete',
            townshipId: this.townshipId,
            organisationId: this.data.orgId,
            email: this.data.user.email,
          },
        })
      )) as any
    ).data;
    if (result.status == 'done') {
      this.dialogRef.close();
    } else {
      this.snackbar.open('Er is een onbekende fout opgetreden.', 'X', {
        duration: 5000,
      });
    }
    this.saving = false;
    this.dialogRef.disableClose = false;
  }
}
