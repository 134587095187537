import { Component, OnInit, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  collection,
  deleteField,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { StaffelFile, Township } from 'src/app/interfaces';
import { CustomValidators } from 'src/app/validators/custom-validators';

interface DialogData {
  staffelFile: StaffelFile | null;
  townshipsAlreadyInStaffels: any[] | null;
}

@Component({
  selector: 'app-confirm-delete-domains',
  templateUrl: './edit-staffel-file.component.html',
  styleUrls: ['./edit-staffel-file.component.scss'],
})
export class EditStaffelFileComponent implements OnInit {
  staffelForm: UntypedFormGroup;
  commissionDiscountStepsForm: UntypedFormGroup;
  townshipsForm: UntypedFormGroup;
  townships: any[] = [];
  commissionDiscountSteps = [];
  staffelId: string;
  loading: boolean = false;
  saving: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<EditStaffelFileComponent>,
    public dialog: MatDialog,
    private db: AngularFirestore,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.staffelForm = this.fb.group({
      name: [this.data.staffelFile?.name ?? null, [Validators.required]],
    });
    this.commissionDiscountStepsForm = this.fb.group({});
    this.townshipsForm = this.fb.group({});

    await this.getDBDataAndPrep();
    this.loading = false;
  }

  async save() {
    this.saving = true;
    if (this.errorCheck()) {
      this.saving = false;
      return;
    }
    this.staffelId =
      this.data.staffelFile?.id ??
      doc(this.db.firestore, `globalSettings/staffelFiles/staffels`).id;

    const commissionDiscountArray = [];

    this.commissionDiscountSteps.forEach((commissionDiscountStep) => {
      const commissionDiscountObj = {};
      commissionDiscountStep.controls.forEach((control) => {
        commissionDiscountObj[control.objectValue] = Number(
          this.commissionDiscountStepsForm.controls[control.name].value
            .toString()
            .replace(',', '.')
        );
      });
      commissionDiscountArray.push(commissionDiscountObj);
    });

    const townshipsArray = [];
    let totalCommission = 0;
    let totalValue = 0;
    this.townships.forEach(async (township) => {
      if (
        township.before &&
        this.townshipsForm.controls[township.formControl].value
      ) {
        townshipsArray.push(township.commissionInfo);
        totalCommission = totalCommission + township.commissionInfo.commission;
        totalValue = totalValue + township.commissionInfo.value;
      }
      if (
        !township.before &&
        this.townshipsForm.controls[township.formControl].value
      ) {
        const saveObj = { staffelId: this.staffelId };
        if (township.commissionInfoFromBackup) {
          saveObj['commissionBackup'] = deleteField();
        }
        townshipsArray.push(township.commissionInfo);
        totalCommission = totalCommission + township.commissionInfo.commission;
        totalValue = totalValue + township.commissionInfo.value;
        await setDoc(
          doc(this.db.firestore, `township/${township.commissionInfo.id}`),
          saveObj,
          { merge: true }
        );
      }
      if (
        township.before &&
        !this.townshipsForm.controls[township.formControl].value
      ) {
        await setDoc(
          doc(this.db.firestore, `township/${township.commissionInfo.id}`),
          {
            staffelId: deleteField(),
            commissionBackup: township.commissionInfo,
          },
          { merge: true }
        );
      }
    });

    const staffelSaveObj = {
      ...this.staffelForm.value,
      commissionDiscount: commissionDiscountArray,
      townships: townshipsArray,
      totalCommission: totalCommission,
      totalValue: totalValue,
    };

    console.log('test2', staffelSaveObj);

    await setDoc(
      doc(
        this.db.firestore,
        `globalSettings/staffelFiles/staffels/${this.staffelId}`
      ),
      staffelSaveObj,
      { merge: true }
    );

    this.saving = false;
    this.dialogRef.close();
  }

  errorCheck() {
    let errorBool = false;
    if (this.staffelForm.invalid) {
      this.staffelForm.markAllAsTouched();
      errorBool = true;
    }
    if (this.commissionDiscountStepsForm.invalid) {
      this.staffelForm.markAllAsTouched();
      errorBool = true;
    }
    if (this.commissionDiscountSteps.length < 1) {
      this.snackBar.open('Minstens een trede vereist', 'X', {
        duration: 5000,
      });
      errorBool = true;
    }
    return errorBool;
  }

  async getDBDataAndPrep() {
    (
      await getDocs(
        query(collection(this.db.firestore, 'township'), orderBy('name'))
      )
    ).forEach((township) => {
      const townshipData = { ...township.data(), id: township.id } as Township;
      if (
        this.data.townshipsAlreadyInStaffels
          .map((township) => township.id)
          .indexOf(townshipData.id) === -1
      ) {
        this.townshipsForm.addControl(
          `${townshipData.id}_checkbox`,
          new UntypedFormControl(false)
        );
        const pushObj = {
          formControl: `${townshipData.id}_checkbox`,
          townshipEnvironment: this.returnImageFile(townshipData.voucherPrefix),
          before: null,
          commissionInfoFromBackup: townshipData.commissionBackup?.commission
            ? true
            : false,
          commissionInfo: {
            id: townshipData.id,
            name: townshipData.name,
            commission: townshipData.commissionBackup?.commission ?? 0,
            value: townshipData.commissionBackup?.value ?? 0,
          },
        };
        this.townships.push(pushObj);
      }
      if (this.data.staffelFile) {
        const townshipIndex = this.data.staffelFile.townships
          .map((township) => township.id)
          .indexOf(townshipData.id);
        if (townshipIndex !== -1) {
          this.townshipsForm.addControl(
            `${township.id}_checkbox`,
            new UntypedFormControl(true)
          );
          const pushObj = {
            formControl: `${township.id}_checkbox`,
            townshipEnvironment: this.returnImageFile(
              townshipData.voucherPrefix
            ),
            before: true,
            commissionInfo: this.data.staffelFile.townships[townshipIndex],
          };
          this.townships.push(pushObj);
        }
      }
    });
    if (this.data.staffelFile?.commissionDiscount) {
      let index = 0;
      this.data.staffelFile.commissionDiscount.forEach(
        (commissionDiscountStep) => {
          this.commissionDiscountStepsForm.addControl(
            `commissionDiscountStep_${index}_percentage`,
            new UntypedFormControl(commissionDiscountStep.percentage, [
              Validators.required,
              CustomValidators.numberInput(false, true, 2),
            ])
          );
          this.commissionDiscountStepsForm.addControl(
            `commissionDiscountStep_${index}_startValue`,
            new UntypedFormControl(commissionDiscountStep.startValue, [
              Validators.required,
              CustomValidators.numberInput(false, true, 2),
            ])
          );
          this.commissionDiscountStepsForm.addControl(
            `commissionDiscountStep_${index}_endValue`,
            new UntypedFormControl(commissionDiscountStep.endValue, [
              Validators.required,
              CustomValidators.numberInput(false, true, 2),
            ])
          );
          this.commissionDiscountSteps.push({
            id: `commissionDiscountStep${index}`,
            controls: [
              {
                name: `commissionDiscountStep_${index}_percentage`,
                label: 'Percentage',
                objectValue: 'percentage',
              },
              {
                name: `commissionDiscountStep_${index}_startValue`,
                label: 'Begin waarde',
                objectValue: 'startValue',
              },
              {
                name: `commissionDiscountStep_${index}_endValue`,
                label: 'Eind waarde',
                objectValue: 'endValue',
              },
            ],
          });
          index++;
        }
      );
    }
  }

  addCommissionStep() {
    const index = this.commissionDiscountSteps.length + 1;
    const controlArray = [
      {
        name: `commissionDiscountStep_${index}_percentage`,
        label: 'Percentage',
        objectValue: 'percentage',
      },
      {
        name: `commissionDiscountStep_${index}_startValue`,
        label: 'Begin waarde',
        objectValue: 'startValue',
      },
      {
        name: `commissionDiscountStep_${index}_endValue`,
        label: 'Eind waarde',
        objectValue: 'endValue',
      },
    ];
    controlArray.forEach((control) => {
      this.commissionDiscountStepsForm.addControl(
        control.name,
        new UntypedFormControl(0, [
          Validators.required,
          CustomValidators.numberInput(false, true, 2),
        ])
      );
    });
    this.commissionDiscountSteps.push({
      id: `commissionDiscountStep${index}`,
      controls: controlArray,
    });
  }

  removeCommissionStep(id) {
    const index = this.commissionDiscountSteps
      .map((commissionDiscount) => commissionDiscount.id)
      .indexOf(id);
    this.commissionDiscountSteps[index].controls.forEach((control) => {
      this.commissionDiscountStepsForm.removeControl(control.name);
    });
    this.commissionDiscountSteps.splice(index, 1);
  }

  returnImageFile(voucherPrefix) {
    let returnString = '';
    switch (voucherPrefix) {
      case 'groene':
        returnString = '../../../assets/images/logos/groenebon.png';
        break;
      case 'lokale':
        returnString = '../../../assets/images/logos/lokalebon.png';
        break;
      case 'toegangs':
        returnString = '../../../assets/images/logos/toegangsbon.png';
        break;
      case 'duurzaamwonen':
        returnString = '../../../assets/images/logos/duurzaambon.png';
        break;
    }
    return returnString;
  }
}
