import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encodeRouteParam, iframeUrl } from 'src/app/globals';
import { Township, Voucher, VoucherGroup } from 'src/app/interfaces';

export interface DialogData {
  voucher: Voucher;
  voucherGroup: VoucherGroup;
  township: Township;
}

@Component({
  selector: 'app-fill-in-bop-form-dialog',
  templateUrl: './fill-in-bop-form-dialog.component.html',
  styleUrls: ['./fill-in-bop-form-dialog.component.scss'],
})
export class FillInBopFormDialogComponent implements OnInit {
  bopUrl: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public db: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.bopUrl = `${iframeUrl(
      this.data.township.id,
      this.db.firestore
    )}/burden-of-proof/${this.data.township.uniqueName}/${encodeRouteParam(
      this.data.voucher.number
    )}/${this.data.voucher.postal}/${this.data.voucher.houseNumber}`;
  }
}
