import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';
import {
  AngularFireAuthGuard,
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/compat/auth-guard';
// import { SelectEntityComponent } from './select-entity/select-entity.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { FirstTimeComponent } from './auth/first-time/first-time.component';

import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { DashboardTownshipComponent } from './dashboard-township/dashboard-township.component';
import { VoucherManagementComponent } from './voucher-management/voucher-management.component';
import { OrganisationManagementComponent } from './organisation-management/organisation-management.component';
import { SettingsComponent } from './settings/settings.component';
import { TagsComponent } from './tags/tags.component';
import { OrdersComponent } from './orders/orders.component';
import { PaymentReferencesComponent } from './payment-references/payment-references.component';
import { PromotionMaterialsComponent } from './promotion-materials/promotion-materials.component';
import { FontsComponent } from './fonts/fonts.component';
import { ExternalVoucherManagementComponent } from './external-voucher-management/external-voucher-management.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { UsersComponent } from './users/users.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { CharacteristicsComponent } from './characteristics/characteristics.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { ThemesComponent } from './themes/themes.component';
import { CheckTownshipVouchersComponent } from './check-township-vouchers/check-township-vouchers.component';
import { GroupLinkComponent } from './group-links/group-links.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { StatisticsManagementComponent } from './statistics/statistics-management/statistics-management.component';
import { SingleWidgetComponent } from './statistics/single-widget/single-widget.component';
import { AccountComponent } from './account/account.component';
import { MultiFactorComponent } from './account/multi-factor/multi-factor.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDefault = () =>
  redirectLoggedInTo([environment.defaultRoute.id]);
// const goToTaskDetail = (next) => hasCustomClaim(`task-${next.params.id}`);

// tslint:disable-next-line: prefer-const
let routes: Routes = [];
const allRoutes = {
  account: {
    path: 'account',
    component: AccountComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  multifactor: {
    path: 'account/multiFactor',
    component: MultiFactorComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  login: {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToDefault },
  },
  registration: {
    path: 'registration',
    component: RegistrationComponent,
  },
  firsttime: {
    path: 'first-time',
    component: FirstTimeComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  dashboardtownship: {
    path: 'dashboard-township',
    component: DashboardTownshipComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  settings: {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  vouchermanagement: {
    path: 'voucher-management',
    component: VoucherManagementComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  externalvouchermanagement: {
    path: 'external-voucher-management',
    component: ExternalVoucherManagementComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  organisationmanagement: {
    path: 'organisation-management',
    component: OrganisationManagementComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  tags: {
    path: 'tags',
    component: TagsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  orders: {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  paymentreferences: {
    path: 'payment-references',
    component: PaymentReferencesComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  promotionmaterials: {
    path: 'promotion-materials/:organisationId',
    component: PromotionMaterialsComponent,
  },
  fonts: {
    path: 'fonts',
    component: FontsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  helpdesk: {
    path: 'helpdesk',
    component: HelpdeskComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  users: {
    path: 'users',
    component: UsersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  generalSettings: {
    path: 'general-settings',
    component: GeneralSettingsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  assignments: {
    path: 'assignments',
    component: AssignmentsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  characteristics: {
    path: 'characteristics',
    component: CharacteristicsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  themes: {
    path: 'themes',
    component: ThemesComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  groupLinks: {
    path: 'group-links',
    component: GroupLinkComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  checktownshipvouchers: {
    path: 'check-vouchers',
    component: CheckTownshipVouchersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  statistics: {
    path: 'statistics',
    component: StatisticsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  widget: {
    path: 'statistics/widget/:townshipId/:widgetId',
    component: SingleWidgetComponent,
  },
  statisticsmanagement: {
    path: 'statistics-management',
    component: StatisticsManagementComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
};
// environment.routes.forEach(route => {
//   routes.push(allRoutes[route]);
// });
// routes.push({
//   path: '**',
//   redirectTo: 'profile',
//   canActivate: [AngularFireAuthGuard],
//   data: { authGuardPipe: redirectUnauthorizedToLogin },
// });

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(router: Router) {
    const config = router.config;
    environment.routes.forEach((route) => {
      config.push(allRoutes[route]);
    });
    config.push({
      path: '**',
      redirectTo: environment.defaultRoute.path,
      data: { authGuardPipe: redirectUnauthorizedToLogin },
    });
    router.resetConfig(config);
  }
}
