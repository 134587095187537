import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Location } from '@angular/common';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

export const app = initializeApp(environment.firebase);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'europe-west1');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public pageTitle: string;
  showNavbar: boolean;
  env = environment;

  constructor(
    private titleService: Title,
    private router: Router,
    private location: Location
  ) {
    this.titleService.setTitle(environment.name);
    this.router.events.subscribe((event) => {
      if (
        this.location.path().includes('/statistics/widget/') ||
        this.location.path().includes('/email')
      ) {
        this.showNavbar = false;
      } else {
        this.showNavbar = true;
      }
    });
  }
  ngOnInit(): void {}
}
