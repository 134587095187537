import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  Auth,
  getAuth,
  multiFactor,
  sendPasswordResetEmail,
} from 'firebase/auth';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  multiFactorEnabled: boolean = false;
  auth: Auth;
  constructor(private router: Router, private snackbar: MatSnackBar) {}

  ngOnInit(): void {
    this.auth = getAuth();
    const multifactorUser = multiFactor(this.auth.currentUser);
    console.log('multifactor', multifactorUser);
    if (multifactorUser.enrolledFactors.length > 0) {
      this.multiFactorEnabled = multifactorUser.enrolledFactors.some(
        (multiFactorInfo) => {
          return multiFactorInfo.factorId === 'phone';
        }
      );
    }
  }

  navigateToMultiFactor() {
    this.router.navigate(['/account/multiFactor']);
  }

  async resetPassword() {
    await sendPasswordResetEmail(this.auth, this.auth.currentUser.email);
    this.snackbar.open(
      'Er wordt een wachtwoord reset link verstuurd indien er een account bekend is op het mailadres',
      'X',
      {
        duration: 5000,
      }
    );
  }
}
