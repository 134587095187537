import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../../environments/environment';
import { Organisation, Township, TownshipUser, User } from '../interfaces';

import { SendVoucherComponent } from '../voucher-management/send-voucher/send-voucher.component';
import { ManageVoucherComponent } from '../voucher-management/manage-voucher/manage-voucher.component';
import { UpgradeDialogComponent } from './dialogs/upgrade-dialog/upgrade-dialog.component';
import { ConfirmationComponent } from '../voucher-management/dialogs/confirmation-code/confirmation-code.component';
import { ChangeTownshipDialogComponent } from '../dashboard-township/dialog/change-township-dialog/change-township-dialog.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  env = environment;
  userDoc: AngularFirestoreDocument<User>;
  userId: string;
  user: Observable<User>;
  userData: User;
  townshipDoc: AngularFirestoreDocument<Township>;
  townshipUser: AngularFirestoreDocument<TownshipUser>;
  $township: Observable<Township>;
  $townshipUser: Observable<TownshipUser>;
  township: Township;
  organisation: Organisation; // Only filled if in organisation environment
  fixed = true;
  // select_entity: boolean;
  userEmail: string;
  avatarUrl: string;
  isSuperUser = false;
  townshipName: string;
  townships: Township[] = [];
  townshipId = localStorage.getItem('township');
  isMemberOfMultipleOrgs = false;
  isHelpdeskUser: boolean;
  isFinancialUser: boolean;
  isControllerUser: boolean;
  isStatisticsUser: boolean;
  // isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  //   .pipe(
  //     map(result => result.matches)
  //   );

  constructor(
    private route: Router,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  async logout() {
    await this.afAuth.signOut();
    if (localStorage.getItem('township')) {
      localStorage.removeItem('township');
    }
    if (localStorage.getItem('organisationId')) {
      localStorage.removeItem('organisationId');
    }
    return location.reload();
  }

  async openSendVoucherDialog() {
    if (!this.userData) {
      return; // err
    }
    this.dialog.open(SendVoucherComponent, {
      width: '450px',
      autoFocus: false,
    });
  }
  openEditVoucherDialog(type: string) {
    this.dialog.open(ManageVoucherComponent, {
      width: '450px',
      data: { type },
    });
  }
  openDiscountDialog(): void {
    this.dialog.open(ConfirmationComponent, {
      width: '373px',
    });
  }
  showUpdate() {
    const dialogRef = this.dialog.open(UpgradeDialogComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  changeTownship(userId: string) {
    const dialogRef = this.dialog.open(ChangeTownshipDialogComponent, {
      width: '650px',
    });
    dialogRef.afterClosed().subscribe(async (townshipId) => {
      if (townshipId) {
        localStorage.setItem('township', townshipId);
        let data: any = { township: townshipId };
        if (this.userData.orgRights) {
          const org = this.userData.orgRights.find((org) => {
            return org.townshipId === townshipId;
          });
          if (org.organisationId) {
            data = { ...data, organisation: org.organisationId };
          }
        }
        this.townshipDoc = this.afs.doc<Township>('township/' + townshipId);
        await setDoc(doc(this.afs.firestore, `users/${userId}`), data, {
          merge: true,
        });

        window.location.reload();
      }
    });
  }

  ngOnInit() {
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.userEmail = user.email;
        this.userDoc = this.afs.doc<User>('users/' + user.uid);
        this.userId = user.uid;
        this.user = this.userDoc.valueChanges();
        this.user.subscribe(async (res) => {
          this.userData = res;
          if (res.township) {
            localStorage.setItem('township', res.township);
          }
          if (res.organisation) {
            localStorage.setItem('organisationId', res.organisation);
          }
          if (this.userData.rights === 'admin') {
            this.isSuperUser = true;
          }
          if (this.userData.orgRights && this.userData.orgRights.length > 1) {
            this.townships = [];
            for (const right of this.userData.orgRights) {
              const township = (
                await getDoc(
                  doc(this.afs.firestore, `township/${right.townshipId}`)
                )
              ).data() as Township;
              township.id = right.townshipId;

              if (township && township.name) {
                this.townships.push(township);
              }
            }
            this.isMemberOfMultipleOrgs = true;
          }
          if (this.userData.township) {
            this.townshipDoc = this.afs.doc<Township>(
              `township/${this.userData.township}`
            );
            this.$township = this.townshipDoc.valueChanges();
            this.$township.subscribe(async (value) => {
              this.township = value;
              this.townshipName = this.township.name;
              if (this.userData.organisation && this.env.env === 'org') {
                this.organisation = (
                  await getDoc(
                    doc(
                      this.afs.firestore,
                      `township/${this.userData.township}/organisations/${this.userData.organisation}`
                    )
                  )
                ).data() as Organisation;
                if (this.organisation.useHeaderImage) {
                  this.avatarUrl = this.organisation.headerImageUrl;
                }
              }
              if (this.env.env === 'township' && this.userData.township) {
                if (this.township?.useLogoImage) {
                  this.avatarUrl = this.township.logoImageUrl;
                }
              }
            });
            if (
              (this.env.env === 'township' &&
                (!this.userData.township || this.userData.organisation)) ||
              (this.env.env === 'org' &&
                (!this.userData.township || !this.userData.organisation))
            ) {
              // Sign user out if it's an organisation account in township env or a township account in an organisation
              localStorage.setItem('logoutRF', '1');
              this.logout();
            }
          }
          if (this.userData.township) {
            this.townshipUser = this.afs.doc<TownshipUser>(
              `township/${this.userData.township}/users/${this.userId}`
            );
            this.$townshipUser = this.townshipUser.valueChanges();
            this.$townshipUser.subscribe(async (value) => {
              if (!value) {
                return;
              }
              if (value.rights === 'helpdesk') {
                this.isHelpdeskUser = true;
              } else if (value.rights === 'financial') {
                this.isFinancialUser = true;
              } else if (value?.rights === 'controller') {
                this.isControllerUser = true;
              } else if (value?.rights === 'statistics') {
                this.isStatisticsUser = true;
              }
            });
          }
        });
      }
    });
  }

  async setTownship(township: Township) {
    let rightsObj = this.userData.orgRights.find(
      (o) => o.townshipId === township.id
    );

    localStorage.setItem('township', rightsObj.townshipId);
    const dataObj =
      this.env.env === 'org'
        ? {
            organisation: rightsObj.organisationId,
            township: rightsObj.townshipId,
          }
        : {
            township: rightsObj.townshipId,
          };

    await setDoc(doc(this.afs.firestore, `users/${this.userId}`), dataObj, {
      merge: true,
    });
    this.townships = [];
    this.township = null;
    window.location.reload();
  }
}
